<template>
  <div class="article-box">
    <div class="image">
      <img :src="prefix + '/'+ article.imgurl" alt="article image" srcset="" class="article-image" />
    </div>
    <div class="placeholder"></div>
     <el-alert :title="errorText" v-if="errorText" type="error"></el-alert>
    <div class="article-content">
      <h1 class="title">{{article.title}}</h1>
      <div>
        <p class="create-date">{{article.created_at}}</p>
        <tag-list :tags="article.tag"></tag-list>
      </div>
      <div class="content">
       <span v-html="article.content" v-highlight></span>
      </div>
      <div class="article-status-group">
        <el-button @click="zan">
          <div class="thumbs-group">
            <img :src="ZanSVG" alt="thumbs up" width="30" height="30" class="thumbs-icon"/>
            <span class="thumbs-count">{{article.zan}}</span>
          </div>
        </el-button>
        <add-this class="share" />
      </div>
    </div>
    <div class="comment-box">
      <div class="comment-div">
        <textarea class="comment-text-area" @focus="comentOpsClick('focus')" v-model="content"></textarea>
        <div class="comment-button-group">
          <el-button @click="comentOpsClick('cancel')" icon="el-icon-delete-solid" type="danger" class="comment-button-item">
            CANCEL
          </el-button>
          <el-button @click="comentOpsClick('confirm')" icon="el-icon-s-promotion" type="primary">
            PUBLISH
          </el-button>
        </div>
      </div>
      <div class="comments-list">
        <p>ALL COMMENTS</p>
        <div v-for="item in comments.data" :key="item.id">
          <hr />
          <div class="comment-item">
            <div class="comment-item-head">
              <div class="head-left">
                <img v-if="item.user.avatar_url" :src="prefix + item.user.avatar_url" alt="comment user avatar" class="comment-avater">
                <i v-else class="el-icon-user comment-avater-default"></i>
                <p class="user-name">&nbsp;&nbsp;&nbsp;&nbsp;{{ fuzzyUserName(item.user) }}</p>
              </div>
              <div class="head-right">
                <p class="user-date">{{ item.created_at }}</p>
                <el-button size="mini" icon="el-icon-connection" @click="replyContent(item)">回复</el-button>
              </div>
            </div>
            <div>
              <p class="comment-content">{{item.content}}</p>
              <reply
              v-if="replyItem.commentId==-1 && replyItem.shown===item.id"
              :replyItem="replyItem"
              @replyStatusChange="replyStatusChange"></reply>
            </div>
            <div class="replies">
              <div class="reply" v-for="reply in item.replies" :key="reply.id">
                <div class="reply-head">
                  <div>
                    <span class="user-name">{{ fuzzyUserName(reply.user) }}</span>回复
                    <span class="user-name">{{ fuzzyUserName(reply.userd) }}</span>:
                  </div>
                  <div>
                    <p class="user-date">{{reply.created_at}}</p>
                    <el-button size="mini" icon="el-icon-connection" @click="replyContent(reply)">回复</el-button>
                  </div>
                </div>
                <p class="comment-content">{{reply.content}}</p>
                <reply
                v-if="replyItem.commentId==item.id && replyItem.shown===reply.id"
                :replyItem="replyItem"
                @replyStatusChange="replyStatusChange"></reply>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <login-dialog
    v-if="loginProps.isShown"
    :login-props= "loginProps"
    @loginEmit="getLoginEmit"></login-dialog>
  </div>
</template>
<script>
import { config } from '@/assets/config'
import ZanSVG from '@/assets/images/thumbs_up.svg'
import LoginDialog from '@/components/logindialog/loginDialog'
import Reply from './components/reply'
import TagList from '../home/components/tagList'
import { setMeta } from '../../utils/siteMeta'
import AddThis from '../../components/addThis/addThis.vue'
export default {
  name: 'Article',
  components: {
    LoginDialog,
    Reply,
    TagList,
    AddThis,
},
  data () {
    return {
      prefix: config.prefix,
      id: this.$route.params.id,
      article: {},
      content: '',
      comments: {},
      errorText: '',
      replyItem: {
        shown: -1,
        commentId: -1, // reply 存储父comment的id comment忽略此项,
        item: null // 保存要回复的目标的记录 props到reply module中
      },
      loginProps: { isShown: false },
      ZanSVG,
    }
  },

  methods: {
    getArticle () {
      this.$axios.get('/api/article/' + this.id)
        .then(res => { 
          this.article = res.data;
          const { title, imgurl, content, tag, created_at, updated_at} = this.article;
          const description = content.replace(/<[^>]+>/g, '').substring(0, 200);
          const logo = this.prefix + imgurl;
          const url = window.location.href;

          setMeta(title, description, logo, url, (tag || [{name: "SEVEN's Blog"}]).map(({ name }) => name), created_at, updated_at)
        })
    },
    getComments () {
      this.$axios.get('/api/article/' + this.id + '/comment')
        .then(res => { this.comments = res.data })
    },
    /**
     * 点击评论发布和textare focus时检测用户是否登录
     */
    comentOpsClick (status) {
      if (status === 'cancel') { return (this.comment = '') }
      if (status === 'focus' && !localStorage.getItem('token')) {
        this.loginProps.isShown = true
      }
      if (status === 'confirm' && localStorage.getItem('token')) {
        if (!this.content) { return }
        this.loginProps.isShown = false
        this.$axios.post('/api/article/' + this.id + '/comment', {
          content: this.content,
          article_id: this.id
        })
          .then(() => { 
            this.getComments();
            this.content = ''
            this.errorText = ''
          })
          .catch(err => { this.errorText = err.messages[0] })
      }
    },
    getLoginEmit (recv) {
      console.log('emit recv:', recv)
      this.loginProps.isShown = false
    },
    /**
     * 显示评论回复和回复回复框
     */
    replyContent (item) {
      if (!localStorage.getItem('token')) {
        this.loginProps.isShown = true
      }
      if (Number.parseInt(localStorage.getItem('scope')) !== 2) {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('scope')
        this.loginProps.isShown = true
      }
      this.replyItem.shown = item.id
      this.replyItem.item = item
      if (item.userd) {
        this.replyItem.commentId = item.comment_id
      } else {
        this.replyItem.commentId = -1
      }
    },
    /**
     * 处理评论回复和回复回复结果
     */
    replyStatusChange (status) {
      if (status.status === 'failure') {
        this.errorText = status.message
        return
      }
      this.replyItem.shown = -1
      this.replyItem.commentId = -1
      this.replyItem.item = null
      if (status.status === 'confirm') { this.getComments() }
    },
    zan () {
      if (!localStorage.getItem('token')) {
        this.loginProps.isShown = true
        return
      }
      this.$axios.post('/api/zan', {
        type: 1,
        typed_id: this.id
      })
        .then(res => {
          if (res.data.status === 0) { this.article.zan -= 1 }
          if (res.data.status === 1) { this.article.zan += 1 }
        })
        .catch(err => { console.log(err) })
    },
    fuzzyUserName({username, phone}) {
      return username === phone ? `***${username.substr(username.length -4)}` : username;
    }
  },
  mounted () {
    this.getArticle()
    this.getComments()
  }
}
</script>
<style lang="stylus" scoped>
.article-box
  width: 100%
  .user-name
    color green
    margin 0px
    line-height 30px
  .user-date
    margin 0px
    line-height 30px
  .comment-content
    font-size 16px
    margin-top 0px
    text-indent 2em
  .placeholder
    height 150px
  .image
    z-index 1
    .article-image
      z-index 1
      position fixed
      width 100%
      height 700px
      object-fit cover
      @media screen and (max-width: 1000px)
        height 400px
  .article-content
    z-index 2
    margin 50px 20%
    @media screen and (max-width: 1000px)
      margin 50px 1px
    background-color #ffffff
    background:rgba(255,255,255,0.9)
    position relative
    padding 10px
    box-shadow 0 0 .01rem .1rem rgba(236, 240, 241, 1.0)
    border-radius: 1px
    .title
      text-align center
      font-size 30px
    .content
      font-size 20px
      word-break: break-all
    .article-status-group
      width 90%
      margin 10px auto
      .thumbs-group
        margin: 0 auto
        display: flex
        justify-content: space-between
        width: 60px
        font-size: 20px
      .share
        margin-top: 20px
    .create-date
      text-align center
  .comment-box
    z-index 2
    margin 50px 20%
    @media screen and (max-width: 1000px)
      margin 50px 1px
    background-color #ffffff
    background:rgba(255,255,255,0.9)
    position relative
    padding 10px
    box-shadow 0 0 .01rem .01rem rgba(236, 240, 241, 1.0)
    .comment-div
      width 90%
      margin 0 auto
      .comment-text-area
        width 100%
        height 200px
        border-radius 10px
        padding 10px
        border: solid 1px #DCDFE6
      .comment-button-group
        margin-top 10px
        display flex
        justify-content flex-end
        .comment-button-item
          margin-right 10px
    .comments-list
      width 90%;
      margin 0 auto
      margin-top 60px
      > p
        font-size 20px
      .comment-item
        .comment-item-head
          display flex
          justify-content space-between
          .head-left
            display flex
          .head-right
            width: 250px
            display flex
            justify-content: space-between
          .comment-avater
            width 30px
            height 30px
            overflow hidden
            object-fit cover
            border-radius 50%
          .comment-avater-default
            line-height: 30px
        .replies
          margin-left 20px
          .reply
            .reply-head
              display flex
              justify-content space-between
              div:nth-child(2)
                width: 250px
                display flex
                justify-content space-between
                margin-right 20px
</style>
