<template>
  <div>
    <el-tag
      v-for="tag in tags" :key="tag.id" class="tag"
      :type="colors[tag.id % colors.length]">
      <i class="el-icon-collection-tag tag-item"></i>{{tag.name}}
    </el-tag>
  </div>
</template>
<script>
export default {
  name: 'TagList',
  props: ['tags'],
  data () {
    return {
      colors: ['primary', 'success', 'info', 'warning', 'danger']
    }
  }
}
</script>
<style lang="stylus" scoped>
.tag
  margin 10px
  .tag-item
    padding: 3px
</style>
