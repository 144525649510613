<template>
  <div id="addThis-component">
    <div class="addthis_inline_share_toolbox"></div>
  </div>
</template>

<script>
export default {
  name: 'AddThis',
  mounted: function () {
    const component = document.getElementById('addThis-component');
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-629ca34877ab3abd')
    component.appendChild(recaptchaScript)
  }
}
</script>

<style>

</style>