<template>
  <div>
    <div class="comment-div">
      <textarea class="comment-text-area" v-model="content"></textarea>
      <div class="comment-button-group">
        <el-button @click="statusChange('cancel')"  icon="el-icon-delete-solid" type="danger" class="comment-button-item">
          CANCEL
        </el-button>
        <el-button @click="statusChange('confirm')"  icon="el-icon-s-promotion" type="primary" color="blue">
          PUBLISH
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Reply',
  props: ['replyItem'],
  data () {
    return {
      content: ''
    }
  },
  methods: {
    statusChange (status) {
      if (status === 'cancel') {
        this.$emit('replyStatusChange', { status: status })
      }
      if (status === 'confirm') {
        let param = {
          comment_id: this.replyItem.shown,
          to_user_id: this.replyItem.item.user.id,
          content: this.content,
          type: 1 // 回复评论
        }
        if (this.replyItem.item.userd) {
          param.type = 2 /* 回复回复 */
          param.comment_id = this.replyItem.commenId
        }
        this.$axios.post('/api/reply', param)
          .then(() => {
            this.$emit('replyStatusChange', { status: 'confirm' })
          })
          .catch(err => {
            this.$emit('replyStatusChange', {
              status: 'failure', message: err.messages[0]
            })
          })
      }
    }
  },
  mounted () { }
}
</script>
<style lang="stylus" scoped>
.comment-text-area
  width 100%
  height 100px
  border: solid 1px #DCDFE6
  border-radius: 10px
  padding: 10px
.comment-button-group
  margin-top 10px
  display flex
  justify-content flex-end
  .comment-button-item
    margin-right 10px
</style>
